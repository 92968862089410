.brochure-cards {
	width: 100%;
	padding: 3rem 0;

	@include media(">=tablet") {
		padding: 4rem 0;
	}

	&__deck {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: stretch;
		gap: 2.7rem;
		flex-wrap: wrap;

		@include media(">=tablet") {
			flex-wrap: nowrap;
		}
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 50%;
		flex-grow: 1;
		height: auto;
		align-items: center;

		@include media(">=tablet") {
			flex-grow: initial;
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		flex-grow: 1;
		transform-origin: center right;
		transition: transform 1s;
		background-color: var(--slide-bg-color);
		overflow: hidden;
		padding: 7rem 1.5rem 4.5rem;
		box-sizing: border-box;

		p {
			font-size: 1.6rem;
			color: $white;
		}

		.card__heading {
			padding-bottom: 2rem;
			text-align: center;

			h2 {
				font-size: 4rem;
			}
		}
	}

	&__image {
		position: relative;
		width: 200px;
		z-index: 9;
		top: 38px;
	}

	.button__wrap {
		display: flex;
		width: calc(100% - 5rem);
		justify-content: center;
		padding-top: 2.8rem;

		@include media(">=tablet") {
			width: 100%;
		}

		.button {
			width: 100%;
			padding: 2rem 0;
			text-align: center;
			background-color: $white;
			color: var(--slide-bg-color);
			font-size: 1.9rem;
			border: .4rem solid var(--slide-bg-color);
			border-radius: 0;
		}
	}

	.four-square {

		table {
			border-collapse: collapse;

			td {
				border: 1px solid $white;

				div {
					font-size: 1.6rem;
				}

				.square-heading {
					font-size: 2.4rem;
					font-weight: 800;
					padding-bottom: 1.7rem;
				}
			}

			tr:first-child {
				td {
					border-top: 0;
				}

				td:first-child {
					border-left: 0;
					padding: 0 3.5rem 1.7rem 0;
				}

				td:last-child {
					border-right: 0;
					padding: 0 0 1.7rem 3.5rem;
				}
			}

			tr:last-child {
				td {
					border-bottom: 0;
				}

				td:first-child {
					border-left: 0;
					padding: 1.7rem 3.5rem 0 0;
				}

				td:last-child {
					border-right: 0;
					padding: 1.7rem 0 0 3.5rem;
				}
			}
		}
	}
}
