.dealer-map {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $asp-blue;

	@include media(">tablet") {
		padding: 7rem 0 0;
	}

	&__img {
		position: relative;

		&::before {
			content: " ";
			background: linear-gradient(0deg, rgba(0, 86, 106, 1) 0%, rgba(0, 86, 106, 0) 100%);
			display: block;
			position: absolute;
			width: 100%;
			height: 20%;
			top: 80%;
			right: auto;
			left: auto;
			bottom: 0;
		}

		@include media(">tablet") {
			display: none;
		}
	}

	&__heading {
		width: 90%;
		text-align: left;

		@include media(">tablet") {
			text-align: center;
			max-width: 42rem;
		}
	}

	h1 {
		margin-bottom: 2.5rem;
		font-size: 3.2rem;
		font-weight: 900;
		text-transform: uppercase;
		z-index: 1;
		line-height: 1.15;
	}

	h2 {
		font-size: 1.6rem;
		font-weight: normal;
		z-index: 1;
		line-height: 1.15;
	}

	&__embed {
		width: 90%;
		margin: 3rem auto;
		z-index: 1;

		@include media(">tablet") {
			width: 82%;
			margin: 7rem auto;
		}
	}
}