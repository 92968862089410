.primary-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4.5rem 3rem;
	background-color: $asp-grey;

	@include media(">tablet") {
		padding: 7rem 6rem;
	}

	.footer-logo {
		max-width: 33.7rem;
		margin-bottom: 4rem;
	}

	.footer-cta {
		margin-bottom: 4rem;
	}

	.footer-links {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin-bottom: 4rem;
		padding: 4rem 0;
		border-top: 1px solid $asp-dark-grey;
		border-bottom: 1px solid $asp-dark-grey;

		&__link {
			margin-bottom: 2rem;
			color: $asp-black;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.footer-content * {
		color: $asp-black;
	}
}