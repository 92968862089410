.hero {

    @include media(">=tablet") {
        display: flex;
        align-items: center;
        width: 100%;
    }
    
    &__background {

        position: relative;
        width: 100%;
        min-height: 25rem;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 200px;
            background: linear-gradient(0deg, #000 0%, transparent 100%);

            @include media(">=tablet") {
                background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 2%, rgba(0,0,0,0.7987570028011204) 31%, rgba(0,0,0,0.5214460784313726) 45%, rgba(0,0,0,0) 75%);
                height: 100%;
            }
        }

        img {
            width: 100%;

            @include media(">=tablet") {
                object-fit: cover;
                max-height: 85rem;
            }
        }
    }

    &__copy {
        position: relative;
        margin: -8rem 0 2.5rem;
        padding: 0 2.5rem;

        p {
            padding: 0.8rem 0 1.2rem;
        }

        h1, h2, h3, h4, h5, h6 {
            text-transform: uppercase;
            line-height: 1.15;
        }

        @include media(">=tablet") {
            position: absolute;
            padding: 0 40% 0 8rem;
            margin: 0;

            h1, h2, h3, h4, h5, h6 {
                font-size: 3rem;
            }
        }

        @include media(">=xl-tablet") {
            padding: 0 40% 0 8rem;

            h1, h2, h3, h4, h5, h6 {
                font-size: 3.5rem;
            }
        }

        @include media(">=desktop") {
            padding: 0 50% 0 18rem;

            h1, h2, h3, h4, h5, h6 {
                font-size: 4rem;
            }
        }
    }
}