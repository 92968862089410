@font-face {
	font-family: DaimlerCS;
	src: url(/fonts/DaimlerCS-Regular.eot);
	src: url(/fonts/DaimlerCS-Regular.eot?#iefix) format("embedded-opentype"), url(/fonts/DaimlerCS-Regular.woff2) format("woff2"), url(/fonts/DaimlerCS-Regular.woff) format("woff"), url(/fonts/DaimlerCS-Regular.ttf) format("truetype"), url(/fonts/DaimlerCS-Regular.svg#DaimlerCS-Regular) format("svg");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: DaimlerCS;
	src: url(/fonts/DaimlerCS-Bold.eot);
	src: url(/fonts/DaimlerCS-Bold.eot?#iefix) format("embedded-opentype"), url(/fonts/DaimlerCS-Bold.woff2) format("woff2"), url(/fonts/DaimlerCS-Bold.woff) format("woff"), url(/fonts/DaimlerCS-Bold.ttf) format("truetype"), url(/fonts/DaimlerCS-Bold.svg#DaimlerCS-Bold) format("svg");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: DaimlerCS;
	src: url(/fonts/DaimlerCS-Demi.eot);
	src: url(/fonts/DaimlerCS-Demi.eot?#iefix) format("embedded-opentype"), url(/fonts/DaimlerCS-Demi.woff2) format("woff2"), url(/fonts/DaimlerCS-Demi.woff) format("woff"), url(/fonts/DaimlerCS-Demi.ttf) format("truetype"), url(/fonts/DaimlerCS-Demi.svg#DaimlerCS-Demi) format("svg");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: DaimlerCS;
	src: url(/fonts/DaimlerCS-Light.eot);
	src: url(/fonts/DaimlerCS-Light.eot?#iefix) format("embedded-opentype"), url(/fonts/DaimlerCS-Light.woff2) format("woff2"), url(/fonts/DaimlerCS-Light.woff) format("woff"), url(/fonts/DaimlerCS-Light.ttf) format("truetype"), url(/fonts/DaimlerCS-Light.svg#DaimlerCS-Light) format("svg");
	font-weight: 300;
	font-style: normal;
}

html {
    font-family: "DaimlerCS", sans-serif;
    color: $white;
	font-size: 62.5%; /* reduces default browser size of 16px to 10px */
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

body {
    background-color: $asp-black;
}

// Font styles // 

h1, h2, h3, h4, h5, h6 {
    font-size: 2.7rem;
    font-weight: 800;
	margin: 0.2rem 0;
	color: $white;
}

p {
     font-size: 1.6rem;
	 line-height: 2.3rem;
     font-weight: normal;
	 margin: 0.2rem 0;
	color: $white;
}

a {
    font-size: 1.6rem;
	line-height: 2.3rem;
    font-weight: normal;
    text-decoration: underline;
    color: inherit;
	margin: 0.2rem 0;
	color: $white;
}

span {
    font-size: 1.6rem;
	line-height: 2.3rem;
    font-weight: normal;
	margin: 0.2rem 0;
}

li {
    font-size: 1.6rem;
	line-height: 2.3rem;
    font-weight: normal;
	margin: 0.2rem 0;
}

label, input, select, button {
    font-size: 1.6rem;
    font-weight: normal;
}