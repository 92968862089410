.button {
    display: inline-block;
    padding: .8rem 1.8rem; 
    line-height: 2rem;
    border-radius: .6rem;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;
	color: $white;

    &--red {
        background: $white;
        color: $asp-red;
        padding-right: 50px;

        &::after {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCI+PGRlZnM+PGNsaXBQYXRoIGlkPSJhIj48cmVjdCB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIGZpbGw9IiNhMDMyMzYiLz48L2NsaXBQYXRoPjwvZGVmcz48ZyBjbGlwLXBhdGg9InVybCgjYSkiPjxwYXRoIGQ9Ik0xNCwwQTE0LDE0LDAsMSwwLDI4LDE0LDE0LjAxNSwxNC4wMTUsMCwwLDAsMTQsME02LDE1YTEsMSwwLDAsMS0yLDBWMTRhMSwxLDAsMCwxLDIsMFptMywxYTEsMSwwLDAsMS0yLDBWMTNhMSwxLDAsMCwxLDIsMFptMywzYTEsMSwwLDAsMS0yLDBWOWExLDEsMCwwLDEsMiwwWm0zLTNhMSwxLDAsMCwxLTIsMFYxMmExLDEsMCwwLDEsMiwwWm0zLDJhMSwxLDAsMCwxLTIsMFYxMWExLDEsMCwwLDEsMiwwWm0zLTJhMSwxLDAsMCwxLTIsMFYxM2ExLDEsMCwwLDEsMiwwWm0yLDBhMSwxLDAsMCwxLTEtMVYxNGExLDEsMCwwLDEsMiwwdjFhMSwxLDAsMCwxLTEsMSIgZmlsbD0iI2EwMzIzNiIvPjwvZz48L3N2Zz4=');
            background-size: 20px;
            background-repeat: no-repeat;
            margin-left: 8px;
        }
    }

    &--blue {
        background: $asp-blue;
    }

    &--grey {
        background: $asp-dark-grey;
    }

    &--black {
        background: $asp-black;
    }

    &__wrap {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 1rem 0;

        @include media(">=tablet") {
            padding: 0;
        }

        a {
            margin: 0;

            &:first-child:nth-last-child(2) {
                margin: 0 1rem 0 0;

                @include media(">=tablet") {
                    margin: 0 2rem 0 0;
                }

                @include media(">=desktop") {
                    margin: 0 3rem 0 0;
                }
            }
        }
    }
}