.faq {
    background-color: $black;

    @include media("<=tablet") {
        background-image: unset !important;
    }

    @include media(">=tablet") {
        position: relative;
        background-color: initial;
        background-size: 100% !important;
        background-repeat: no-repeat;
        background-position-y: top !important;
        padding: 4rem 5rem;

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.85);
        }
    }

    @include media(">=desktop") {
        padding: 7rem 8rem;
    }

	.umb-block-list {
		display: flex;
		flex-wrap: wrap;
	}

    &__outer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;

        @include media(">=tablet") {
            justify-content: center;
            position: relative;
            padding: 3rem 4rem;

            /* Using linear gradients to create unconnected corner borders */
            background:
            linear-gradient(to right, white 2px, transparent 2px) 0 0,
            linear-gradient(to right, white 2px, transparent 2px) 0 100%,
            linear-gradient(to left, white 2px, transparent 2px) 100% 0,
            linear-gradient(to left, white 2px, transparent 2px) 100% 100%,
            linear-gradient(to bottom, white 2px, transparent 2px) 0 0,
            linear-gradient(to bottom, white 2px, transparent 2px) 100% 0,
            linear-gradient(to top, white 2px, transparent 2px) 0 100%,
            linear-gradient(to top, white 2px, transparent 2px) 100% 100%;
            background-repeat: no-repeat;
            background-size: 3.5rem 3.5rem;
        }

        @include media(">=desktop") {
            padding: 6rem 7rem;
        }

        @include media(">=widescreen") {
            padding: 8rem 7rem;
        }
    }

    &__image {
        position: relative;
        width: 100%;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 200px;
            background: linear-gradient(0deg, $black 0%, transparent 100%);

            @include media(">=tablet") {
                background: none;
            }
        }

        img {
            width: 100%;

            @include media(">=tablet") {
                display: none;
            }
        }
    }

    &__copy {
        position: relative;
        padding: 0 2.5rem;
        width: 100%;
        color: $white;

        h1, h2, h3, h4, h5, h6 {
            font-size: 2rem;
            text-transform: uppercase;
            line-height: 1.15;
        }

        // @include media(">=tablet") {
        //     &.faq__column {
        //         width: 50%;
        //         box-sizing: border-box;
        //         text-align: left;
    
        //          &--left {
        //              order: 2;
        //          }
    
        //          &--right {
        //             order: 3;
        //         }
        //     }
        // }
    }

    &__heading {
        margin: -0.5rem 0 0;
        padding: 0 2.5rem 2rem;
        text-align: left;
        color: $white;

        h1, h2, h3, h4, h5, h6 {
            text-transform: uppercase;
            line-height: 1.15;
        }

        @include media(">=tablet") {
            width: 100%;
            padding: 3.5rem 0 4.5rem;
            text-align: center;
            // order: 1;
        }   
    }

    &__accordion {

        position: relative;
        width: 100%;
        padding: 2rem 2.5rem;

        // @include media(">=tablet") {
        //     order: 4;
        // }

        h1, h2, h3, h4, h5, h6 {
            padding: 1.5rem 2rem;
            text-transform: uppercase;
        }

        dl, dt, dd {
            padding: 0;
            margin: 0;
        }

        dt {
            padding: 1.4rem 2rem;
            margin-bottom: 0.3rem;
            background: var(--question-bg-color);
            color: var(--question-text-color);
            cursor: pointer;
            font-size: 1.6rem;
            font-weight: 800;
            
            &:after {
                content: var(--question-arrow);
                float: right;
            }

            &.active:after {
                transform: rotate(180deg);
            }

            &.active+dd {
                max-height: 125rem;
                transition: max-height 1s ease-in-out;
            }
        }

        dd {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.4s cubic-bezier(0, 1, 0, 1);
        }

        .faq__answer {
            padding: 1.5rem 0;
        }

    }   
}