.two-col {

    background-color: $asp-blue;
    
    @include media("<=tablet") {
        background-image: unset !important;
    }

    @include media(">=tablet") {
        position: relative;
        background-color: initial;
        background-size: cover !important;
        background-position-y: bottom !important;
        padding: 4rem 5rem;

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0,86,106,0.85);
        }
    }

    @include media(">=desktop") {
        padding: 7rem 8rem;
    }

    &__content {
        @include media(">=tablet") {
            display: flex;
            align-items: center;
        }
    }

    &__outer {
        @include media(">=tablet") {
            position: relative;
            padding: 3rem 4rem;

            /* Using linear gradients to create unconnected corner borders */
            background:
            linear-gradient(to right, white 2px, transparent 2px) 0 0,
            linear-gradient(to right, white 2px, transparent 2px) 0 100%,
            linear-gradient(to left, white 2px, transparent 2px) 100% 0,
            linear-gradient(to left, white 2px, transparent 2px) 100% 100%,
            linear-gradient(to bottom, white 2px, transparent 2px) 0 0,
            linear-gradient(to bottom, white 2px, transparent 2px) 100% 0,
            linear-gradient(to top, white 2px, transparent 2px) 0 100%,
            linear-gradient(to top, white 2px, transparent 2px) 100% 100%;
            background-repeat: no-repeat;
            background-size: 3.5rem 3.5rem;
        }

        @include media(">=desktop") {
            padding: 6rem 7rem;
        }

        @include media(">=widescreen") {
            padding: 8rem 7rem;
        }
    }

    &__image {

        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 200px;
            background: linear-gradient(0deg, $asp-blue 0%, transparent 100%);

            @include media(">=tablet") {
                background: none;
            }
        }

        img {
            width: 100%;

            @include media(">=tablet") {
                box-shadow: 0 0 2rem $asp-black;
            }
        }

        @include media(">=tablet") {
            width: 50%;
        }
    }

    &__copy {

        position: relative;
        margin: -0.5rem 0 0;
        padding: 0 2.5rem 2rem;

        p {
            margin: 0;
        }

        h1, h2, h3, h4, h5, h6 {
            text-transform: uppercase;
            line-height: 1.15;
        }

        .copy {
            &__summary {
                padding: 1.5rem 0;
            }
        }

        @include media(">=tablet") {
            width: 50%;
            box-sizing: border-box;
            padding: 0 0 0 6rem;
        }

        @include media(">=desktop") {
            padding: 0 6rem;
        }
    }
}