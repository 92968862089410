.contact {
    background-color: $black;

    @include media("<=tablet") {
        background-image: unset !important;
    }

    @include media(">=tablet") {
        position: relative;
        background-color: initial;
        background-size: 100% !important;
        background-repeat: no-repeat;
        background-position-y: top !important;
        padding: 4rem 5rem;

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.85);
        }
    }

    @include media(">=desktop") {
        padding: 7rem 8rem;
    }

    &__outer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;

        @include media(">=tablet") {
            justify-content: center;
            position: relative;
            padding: 3rem 4rem 3rem;
            /* Using linear gradients to create unconnected corner borders */
            background: linear-gradient(to right, white 2px, transparent 2px) 0 0, linear-gradient(to right, white 2px, transparent 2px) 0 100%, linear-gradient(to left, white 2px, transparent 2px) 100% 0, linear-gradient(to left, white 2px, transparent 2px) 100% 100%, linear-gradient(to bottom, white 2px, transparent 2px) 0 0, linear-gradient(to bottom, white 2px, transparent 2px) 100% 0, linear-gradient(to top, white 2px, transparent 2px) 0 100%, linear-gradient(to top, white 2px, transparent 2px) 100% 100%;
            background-repeat: no-repeat;
            background-size: 3.5rem 3.5rem;
        }

        @include media(">=desktop") {
            padding: 6rem 7rem;
        }

        @include media(">=widescreen") {
            padding: 8rem 7rem;
        }
    }

    &__image {
        position: relative;
        width: 100%;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 200px;
            background: linear-gradient(0deg, $black 0%, transparent 100%);

            @include media(">=tablet") {
                background: none;
            }
        }

        img {
            width: 100%;

            @include media(">=tablet") {
                display: none;
            }
        }
    }

    &__copy {
        position: relative;
        padding: 0 2.5rem 2rem;
        margin: -3rem 0 0;

        h1, h2, h3, h4, h5, h6 {
            font-size: 3.1rem;
            text-transform: uppercase;
            line-height: 1.15;
        }

        @include media(">=tablet") {
            padding: 0 25% 3.5rem;
            margin: 0;
            text-align: center;
        }

        @include media(">=xl-tablet") {
            padding: 0 30% 3.5rem;
        }

        @include media(">=desktop") {
            padding: 0 35% 3.5rem;
        }
    }

    &__forms {
        padding: 0 2.5rem 3.5rem;

        @include media(">=tablet") {
            padding: 0 6rem;
        }

        @include media(">=desktop") {
            padding: 0 15rem;
        }

        @include media(">=widescreen") {
            padding: 0 20rem;
        }

        form {
            display: flex;
            flex-direction: column;
            row-gap: 2.5rem;

            @include media(">=tablet") {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-end;
                gap: 1rem 2%;
            }

            label {
                flex-basis: 48%;
                margin-bottom: 2rem;

                span {
                    display: inline-block;
                }

                :has(span) {
                    margin-bottom: 0;
                }

                &[for='additional-info'] {
                    display: none;
                }

                &.full-width {
                    flex-basis: 100%;
                }
            }

            input, & select, textarea {
                width: 100%;
                box-sizing: border-box;
                padding: 1.7rem 2rem;
                border-radius: .7rem;

                &[type='submit'] {
                    max-width: 20rem;
                    padding: 1.4rem 4rem;
                    margin: 0rem 0 1rem;
                    line-height: 2rem;
                    border: none;
                    border-radius: .6rem;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 1.8rem;
                    font-weight: bold;
                    color: $white;
                    background: $asp-blue;
                    cursor: pointer;

                    @include media(">=tablet") {
                        margin: 2rem 0 1rem;
                    }
                }
            }

            .checkboxes {
                flex-basis: 100%;
                padding: 0;
                font-size: 1.4rem;

                input {
                    width: initial;
                }
            }
        }

        .submit-messages {
            flex-basis: 100%;

            .submit-message-success {
                display: none;

                &--visible {
                    display: block;
                }

                p {
                    font-size: 1.6rem;
                    color: #6cc360;
                }
            }

            .submit-message-fail {
                display: none;

                &--visible {
                    display: block;
                }

                p {
                    font-size: 1.6rem;
                    color: #ff4543;
                }
            }

            .submit-message-wait {
                display: none;
                animation: l1 1s linear infinite alternate;

                &--visible {
                    display: block;
                }

                &:before {
                    content: "Sending..."
                }

                @keyframes l1 {
                    to {
                        opacity: 0
                    }
                }
            }
        }
    }
}
