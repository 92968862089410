.site-nav {
	position: relative;
	display: grid;
	width: 100%;
	height: 8rem;
	border-bottom: 1px solid $asp-dark-grey;
	align-items: center;
	grid-template-areas:
		"logo . menuButton";
	z-index: 10;

	.logo {
		max-height: 8rem;
		grid-area: logo;

		@include media(">desktop") {
			margin-left: 3.5rem;
		}

		&__img {
			width: 100%;
			height: auto;
			max-height: 8rem;
			object-fit: contain;
		}
	}

	&__menu {
		position: absolute;
		margin: 80px 0 0 0;
		padding: 10px 0 0 0;
		width: 80%;
		height: 100vh;
		border-left: 1px solid $asp-dark-grey;
		right: -1000px;
		top: 0;
		z-index: 2;
		transition: right 0.3s ease-in;
		background-color: $black;
		list-style: none;

		&.active {
			right: 0;
		}

		@include media(">desktop") {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin: 0;
			padding: 0;
			width: auto;
			height: auto;
			border-left: none;
			top: auto;
			right: auto;
		}
	}

	&__menu-item {
		margin: 0;
		padding: 0;
		text-transform: uppercase;
		padding: 3rem;
		border-bottom: 1px solid $asp-dark-grey;

		> a {
			font-size: 2.1rem;
			font-weight: 900;
			text-decoration: none;
		}

		@include media(">desktop") {
			padding: 2rem;
			border-bottom: none;

			> a {
				font-size: 1.6rem;
			}
		}
	}
}

.menu-button {
	display: flex;
	flex-grow: 0;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	cursor: pointer;
	border: none;
	border-left: 1px solid $asp-dark-grey;
	outline: none;
	padding: .5rem;
	width: 7rem;
	color: $white;
	position: sticky;
	left: 0;
	top: 0;
	align-self: normal;
	justify-self: flex-end;
	z-index: 10;
	grid-area: menuButton;

	@include media(">desktop") {
		display: none;
	}

	&:active, &.active {
		transform: translateY(-.2rem);
	}

	&:hover > .menu-button__lines {
		gap: 8px;
	}

	&__lines {
		display: grid;
		gap: 5px;
		transition: all .2s ease;
		position: absolute;
		left: auto;
		right: auto;

		&--open {
			gap: 0;

			.menu-button__line {
				grid-row-start: 1;
				grid-column-start: 1;
				transform: rotate(45deg) scale(1.6);
			}

			.menu-button__line:nth-child(3) {
				transform: rotate(-45deg) scale(1.6);
			}
		}
	}

	&__line {
		display: block;
		transition: .2s ease;
		content: '';
		width: 3.7rem;
		height: 2px;
		background-color: $white;
		transform-origin: center;
		border-radius: 1rem;
	}
}