.carousel-hero {

  @include media(">=tablet") {
    position: relative;
    background-image: var(--background-img);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
  }

  .carousel-background {
    position: relative;

    @include media(">=tablet") {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 200px;
      background: linear-gradient(0deg, #000 0%, transparent 100%);

      @include media(">=tablet") {
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 2%, rgba(0,0,0,0.7987570028011204) 31%, rgba(0,0,0,0.5214460784313726) 45%, rgba(0,0,0,0) 75%);
        height: 100%;
      }
    }
  }

  .swiper {
    .swiper-slide {
      box-sizing: border-box;
      background-color: var(--slide-bg-color);
      height: auto;

      @include media(">=tablet") {
        height: 100vh;
      }

      .content-wrapper {
        width: 100%;
        height: 100%;
        bottom: 0;
        padding: 1.5rem;
        box-sizing: border-box;
        background-color: var(--slide-bg-color);

        @include media(">=tablet") {
          position: absolute;
          width: 600px;
          left: 8rem;
          top: 15rem;
          height: unset;
          bottom: unset;
        }
      }

      &__inner {
        box-sizing: border-box;
        height: 100%;
        position: relative;
        padding: 1.5rem;
        /* Using linear gradients to create unconnected corner borders */
        background: linear-gradient(to right, white 2px, transparent 2px) 0 0, linear-gradient(to right, white 2px, transparent 2px) 0 100%, linear-gradient(to left, white 2px, transparent 2px) 100% 0, linear-gradient(to left, white 2px, transparent 2px) 100% 100%, linear-gradient(to bottom, white 2px, transparent 2px) 0 0, linear-gradient(to bottom, white 2px, transparent 2px) 100% 0, linear-gradient(to top, white 2px, transparent 2px) 0 100%, linear-gradient(to top, white 2px, transparent 2px) 100% 100%;
        background-repeat: no-repeat;
        background-size: 1.8rem 1.8rem;

        @include media(">=tablet") {
          padding: 2rem 12% 2rem 12%;
        }

        @include media(">=desktop") {
          padding: 2rem 6% 2rem 12%;
        }
      }

      &__copy {
        .header {
          font-size: 2.7rem;
          text-transform: uppercase;
          padding-bottom: 1.5rem;

          @include media(">=tablet") {
            font-size: 3rem;
          }

          @include media(">=desktop") {
            font-size: 3.5rem;
          }

          @include media(">=widescreen") {
            font-size: 4rem;
          }
        }

        .subheader {
          padding-bottom: 1rem;

          @include media(">=tablet") {
            padding-bottom: 2rem;
          }
        }
      }
    }

    .swiper-pagination {
      // bullet styling variables
      --swiper-pagination-color: #ffffff;
      --swiper-pagination-bullet-inactive-color: #000000;
      --swiper-pagination-bullet-inactive-opacity: 0.5;
      --swiper-pagination-bullet-size: 10px;

      @include media(">=tablet") {
        padding-bottom: 8rem;
      }
    }

    .swiper-nav-arrow {
      display: none;

      @include media(">=tablet") {
        display: block;
      }

      --swiper-navigation-size: 32px;
      color: #ffffff;
    }

    .swiper-button-next {
      margin-right: 10px;
    }

    .swiper-button-prev {
      margin-left: 10px;
    }
  }
}
