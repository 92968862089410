$white: #ffffff;
$black: #000000;
$asp-black: #010003;
$asp-blue: #00566A;
$asp-red: #A03236;
$asp-grey: #CECFD0;
$asp-dark-grey: #8B8D8E;
$dark-grey: #4A4F54;
$light-grey: #E6E6E6;
$grey: #707070;

$breakpoints: (
	'phone': 320px,
	'tablet': 768px,
	'desktop': 1024px,
	'desktop-lg': 1440px,
	) !default;

html {
	font-size: 62.5%; /* reduces default browser size of 16px to 10px */
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
}

body {
	position: relative;
	overflow-x: hidden;
	&.no-scroll {
		overflow: hidden;
	}
}

img {
	width: 100%;
	height: auto;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}