.umb-block-list {
    &__block {
        &--view {
            p, span, a, li, input, dt, select, option {
                font-size: 1rem !important;
                line-height: 1.3rem !important;
            }
    
            h1, h2, h3, h4, h5, h6 {
                font-size: 1.4rem !important;
                line-height: 1.7rem !important;
            }
    
            .hero {
                &__copy {
                    padding: 0 50% 0 8rem !important;
                }
            }
    
            .two-col {
                padding: 3rem 4rem !important;
    
                &__outer {
                    padding: 2rem 3rem !important;
                }
    
                &__copy {
                    padding: 0 3rem !important;
                }
            }
    
            .info-cards {
                padding: 5rem 0 !important;
    
                &__deck {
                    gap: 0.8rem !important;
                }
    
                &__wrap {
                    width: 16rem !important;
                    height: 16rem !important;
                }    
    
                &__card {
                    width: 16rem !important;
                    height: 16rem !important;
                }
    
                &__face {
                    padding: 0 0.5rem !important;
                }
            }
    
            .faq {
                padding: 3rem 4rem !important;
    
                &__outer {
                    padding: 3rem 4rem !important;
                }
    
                &__heading {
                    padding: 1.5rem 0 2.5rem !important;
                }
            }
    
            .dealer-map {
                h2 {
                    font-size: 1rem !important;
                    line-height: 1.2rem !important;
                }
            }
    
            .contact {
                padding: 3rem 4rem !important;
    
                &__outer {
                    padding: 3rem 4rem !important;
                }
    
                &__copy {
                    padding: 0 15% 2.5rem !important;
                }
    
                &__forms {
                    padding: 0 10rem !important;
    
                    label {
                        font-size: 1rem !important;
                        line-height: 1.2rem !important;
                        color: #ffffff !important;
                    }
                }
            }
        }
    }
}